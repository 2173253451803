import range from 'lodash/range';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import Button from 'react-bootstrap/lib/Button';
import PrefIcons from '../hotel/PrefIcons';

import {format as formatDistance} from 'airborne/homepage2/helpers/searchDistance';

import {format as formatPrice} from 'airborne/utils/price';

function stars(number) {
    return range(number).map((id)=> <Glyphicon key={id} glyph="star" />);
}


export default class MarkerPopover extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        distance: PropTypes.number,
        direction: PropTypes.string,
        'trustyou_rating': PropTypes.number,
        'hotel_rating': PropTypes.number,
        'client_preferred': PropTypes.bool,
        'client_preferred_by_chain': PropTypes.bool,
        'client_preference_tier': PropTypes.number,
        'tmc_preferred': PropTypes.bool,
        'tmc_preferred_by_chain': PropTypes.bool,
        thumbnails: PropTypes.array,
        available: PropTypes.shape({
            'is_available': PropTypes.bool,
            'min_avg_rate': PropTypes.number,
            'currency': PropTypes.string,
            'promo_messages': PropTypes.array,

        }),
        onClick: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    handleClick = () => {
        const {onClick, id, name} = this.props;
        onClick({id, name});
    }

    render() {
        const {
            name,
            available,
            address,
            distance,
            direction,
            thumbnails: [imageSrc=null],
            'client_preferred_by_chain': clientPreferredByChain,
            'client_preference_tier': tierPref,
            'client_preferred': clientPref,
            'tmc_preferred': tmcPref,
            'tmc_preferred_by_chain': tmcPreferredByChain,
            'hotel_rating': rating,
            'trustyou_rating': tyRating,
        } = this.props;
        const {
            'is_available': isAvailable,
            'min_avg_rate': min,
            'currency': currency,
        } = available || {};
        const distanceUnits = settings.USER['distance_units'];
        return (<div className="map-view__details">
            <div className="map-view__details__close" onClick={this.props.onClose}>
                <Glyphicon glyph="remove" />
            </div>
            {imageSrc && (<div className="map-view__details__photo" style={{backgroundImage: `url(${imageSrc}669x414.jpg)`}} />)}

            <h4 className="map-view__details__name">
                <Button bsStyle="link" onClick={this.handleClick} >{name}</Button>
            </h4>
            <div className="map-view__details__addr">
                {address}
            </div>
            <div className="map-view__details__footer">
                <div className="map-view__details__rating">
                    {stars(rating)}
                </div>
                <PrefIcons client={clientPref} tmc={tmcPref} tier={tierPref} tmcByChain={tmcPreferredByChain} clientByChain={clientPreferredByChain} className="map-view__icons" />
            </div>
            <div className="map-view__details__info">
                <ul className="map-view__details__list list-unstyled">
                    <li>
                        {gettext('Distance:')}
                        &nbsp;
                        {formatDistance(distance, distanceUnits, direction)}
                    </li>
                    <li>{gettext('Rating:')} {rating}</li>
                    <li>{gettext('TrustYou rating:')} {tyRating || 'N/A'}</li>
                </ul>

                {isAvailable && (<div className="map-view__details__price">
                    <strong>{formatPrice(min, currency)}</strong>
                    <div className="small">{gettext('avg. / night')}</div>
                </div>)}
            </div>
            <div className="map-view__details__control">
                <Button bsStyle="primary" className="btn-block" onClick={this.handleClick}>
                    {gettext('See rates')}
                </Button>
            </div>
        </div>);
    }
}
